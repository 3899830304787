<template>
  <div>
    <b-sidebar
      id="sidebar-email"
      ref="sidebarEmail"
      v-model="isShowSidebar"
      aria-labelledby="sidebar-email-header"
      right
      backdrop
      shadow
      no-header
      width="80vw"
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter">Email</b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="hide">x</button>
          </b-col>
        </b-row>
        <div class="mt-3">
          <div v-if="isLoading">
            <img
              src="@/assets/icons/loading.svg"
              class="loading"
              alt="loading"
            />
          </div>
          <div v-else>
            <div>
              <InputText
                textFloat="Subject"
                type="text"
                placeholder=""
                v-model="subjectValue"
              />
            </div>
            <div
              v-if="typeEmail === 'segment' && formMain.segmentationType === 2"
            >
              <TextEditorEmailCampaign
                v-if="!isLoading"
                textFloat="Message"
                v-model="emailValue"
                :rows="8"
                :name="'email_value' + 1"
                placeholder="Type something..."
                @onDataChange="(val) => (emailValue = val)"
                isRequired
                :formMain="formMain"
                :isValidate="errorEmail"
              />
              <div v-if="errorEmail" class="mt-1 text-error">Please input.</div>
            </div>
            <div v-else>
              <TextEditorEmail
                ref="textEditorEmail"
                v-if="!isLoading"
                textFloat="Message"
                :variableItem="variableItem"
                v-model="emailValue"
                :name="'email_value' + 1"
                placeholder="Type something..."
                @onDataChange="(val) => (emailValue = val)"
                isRequired
                :formMain="formMain"
                :isValidate="errorEmail"
              />
              <div v-if="errorEmail" class="mt-1 text-error">Please input.</div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              block
              variant="light"
              class="text-body rounded-pill btn-main"
              @click.prevent="submitEmail"
            >
              Save
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import TextEditorEmailCampaign from "@/components/automation/detail/TextEditorEmailCampaign";
import TextEditorEmail from "@/components/inputs/grapesjs/TextEditor";

const variableList = [
  "firstname",
  "lastname",
  "point",
  "telephone",
  "invoiceNo",
  "orderDateYear",
  "orderDateMonth",
  "orderDateDay",
  "branchNameTH",
  "branchNameEN",
  "salesNo",
  "salesFirstnameTH",
  "salesLastnameTH",
  "salesFirstnameEN",
  "salesLastnameEN",
  "transactionCentralPoint",
  "transactionBranchPoint",
  "transactionBranchPointBalance",
];
const variableRedeemList = [
  "redeemPoint",
  "redeemItemName",
  "redeemBranchName",
  "transactionBranchPointBalance",
];
const variableMission = [
  "MissionName",
  "MissionCurrentStep",
  "MissionExpireMonth",
  "MissionExpireDay",
  "MissionExpireYear",
];
const variableMission2 = [
  "MissionName",
  "MissionExpireMonth",
  "MissionExpireDay",
  "MissionExpireYear",
];
const variableMission3 = [
  "MissionName",
  "MissionRewardName",
  "MissionExpireMonth",
  "MissionExpireDay",
  "MissionExpireYear",
];
export default {
  components: {
    TextEditorEmail,
    TextEditorEmailCampaign,
  },
  props: {
    form: {
      required: true,
      type: Array,
    },
    typeEmail: {
      required: true,
      type: String,
    },
    formMain: {
      required: true,
      type: Object,
    },
  },
  watch: {
    isShowSidebar(val) {
      if (val) {
        setTimeout(async () => {
          this.isLoading = false;
        }, 1000);
      } else {
        this.isLoading = true;
      }
    },
    emailValue(val) {
      if (val) {
        this.errorEmail = false;
      } else {
        this.errorEmail = true;
      }
    },
  },
  data() {
    return {
      isLoading: true,
      isShowSidebar: false,
      variableItem: [
        { id: 1, name: "firstname" },
        { id: 2, name: "lastname" },
        { id: 3, name: "point" },
        { id: 4, name: "telephone" },
        { id: 5, name: "memberId" },
      ],
      variableItemSegment: [
        { id: 1, name: "firstname" },
        { id: 2, name: "lastname" },
        { id: 3, name: "point" },
        { id: 4, name: "url_web" },
        { id: 5, name: "url_line" },
        { id: 6, name: "memberId" },
      ],
      emailValue: "",
      subjectValue: "",
      errorEmail: false,
    };
  },
  methods: {
    show() {
      if (this.formMain.event_type_id == 6) {
        var i = 6;
        for (const list of variableList) {
          this.variableItem.push({ id: i, name: list });
        }
      } else if (this.formMain.event_type_id == 12) {
        var i = 14;
        for (const list of variableRedeemList) {
          this.variableItem.push({ id: i, name: list });
        }
      } else if (
        this.formMain.event_type_id == 14 ||
        this.formMain.event_type_id == 17
      ) {
        var i = 14;
        for (const list of variableMission2) {
          this.variableItem.push({ id: i, name: list });
        }
      } else if (this.formMain.event_type_id == 15) {
        var i = 14;
        for (const list of variableMission) {
          this.variableItem.push({ id: i, name: list });
        }
      } else if (this.formMain.event_type_id == 16) {
        var i = 14;
        for (const list of variableMission3) {
          this.variableItem.push({ id: i, name: list });
        }
      }
      this.isShowSidebar = true;
      for (const item of this.form) {
        if (item.communication_type_id === 1) {
          this.emailValue = item.detail_list[0].communication_value[0].value;
          this.subjectValue =
            item.detail_list[0].communication_value[0].subject;
        }
      }
    },
    hide() {
      this.isShowSidebar = false;
      this.variableItem = [
        { id: 1, name: "firstname" },
        { id: 2, name: "lastname" },
        { id: 3, name: "point" },
        { id: 4, name: "telephone" },
        { id: 5, name: "memberId" },
      ];
    },
    async submitEmail() {
      if (this.emailValue) {
        if (
          !(
            this.typeEmail === "segment" && this.formMain.segmentationType === 2
          )
        ) {
          await this.$refs.textEditorEmail.getHtml();
        }
        for (const item of this.form) {
          if (item.communication_type_id === 1) {
            item.detail_list[0].communication_value[0].value = this.emailValue;
            item.detail_list[0].communication_value[0].subject =
              this.subjectValue;
          }
        }
        this.errorEmail = false;
        this.$emit("settingEmail", 1);
        this.hide();
      } else {
        this.errorEmail = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}

::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
.bg-color {
  background-color: var(--primary-color);
}
.title-msg {
  color: #000;
  font-size: 17px;
}
.btn-outline {
  background-color: var(--secondary-color);
  border-radius: 50px;
  color: var(--primary-color);
}
::v-deep .btn-secondary {
  background-color: #fff;
  border-color: #fff;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.text-error {
  color: red;
}
.border-error {
  border-color: red;
}
::v-deep .b-sidebar#sidebar-email {
  width: calc(100% - var(--margin-spacing));
}
</style>
